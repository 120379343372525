
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MvModal, MvIconSprite } from '@monosolutions/vue-components'

@Component({
  components: {
    MvModal,
    MvIconSprite
  }
})
export default class Modal extends Vue {
    @Prop({ default: '' }) title!: string
    @Prop({ default: '' }) description!: string
    @Prop({ default: true }) showDescription!: boolean
    @Prop({ default: 0 }) contentMaxHeight!: number
    @Prop({ default: 'basic' }) type!: string
    @Prop({ default: 'small' }) size!: string
    @Prop({ default: true }) show!: boolean
    @Prop({ default: false }) infoIcon!: boolean
    @Prop({ default: false }) firstButton!: boolean
    @Prop({ default: true }) secondButton!: boolean
    @Prop({ default: '' }) firstButtonText!: string
    @Prop({ default: '' }) secondButtonText!: string
    @Prop({ default: '' }) illustrationType!: string
    @Prop({ default: '' }) illustrationImageSrc!: string
    @Prop({ default: '' }) illustrationIconType!: string
}
