
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '@/mixins/StepMixin'
import { cloneDeep } from 'lodash'
import HTTPClient from '@/utils/HTTPClient'
import { v4 as uuid } from 'uuid'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    Modal
  }
})
export default class Finish extends Mixins(Step) {
  error = false

  mounted (): void {
    (async () => {
      const apiUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:3000/dev'
        // ? 'http://u689.site.docker/api.php/diy'
        : 'https://mono.site/api.php/diy'
      const apiRequest = new HTTPClient(apiUrl)

      const heap = (window as any).heap
      const diyHeapIdentifyId: string = uuid()

      const storeData = this.mapToApi(cloneDeep(this.$store.state.userData))

      if (heap) {
        heap.identify(diyHeapIdentifyId)
        heap.addUserProperties({
          city: storeData.businessInfo.city,
          vertical: storeData.vertical.choice,
          skill: storeData.skill.choice,
          template: storeData.template.templateId,
          subscriptionType: storeData.account.subType,
          email: storeData.account.email
        })
      }

      try {
        const createSite = await apiRequest.post('/site', {
          a: storeData.account,
          d: storeData.businessInfo,
          e: {
            companyType: storeData.vertical.choice,
            skill: storeData.skill.choice,
            templateId: storeData.template.templateId,
            diyHeapIdentifyId
          }
        })

        console.info(createSite.data.data.loginUrl)

        if (createSite.data.data.loginUrl) {
          window.location.href = createSite.data.data.loginUrl
        }
      } catch (error) {
        console.error('CALL_FAILED')
        this.error = true
      }
    })()
    setTimeout(() => {
      this.$store.commit('SET_PROGRESS', 100)
    }, 3000)
  }

  goBack (): void {
    history.back()
  }

  private mapToApi (data: any): any {
    data.businessInfo.companyName = data.company.name
    data.businessInfo.phone = data.businessInfo.phoneNumber
    data.businessInfo.address = data.businessInfo.street

    data.account.subType = 'mono_site_start'

    delete data.businessInfo.phoneNumber
    delete data.businessInfo.street

    return data
  }
}
